
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import CuentaEmisora from '../../../store/entities/OZONE/cuentaEmisora'
@Component({
  components: { Modal, BaseButton, BaseCheckbox }
})
export default class EditCuentaEmisora extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  cuentaEmisora: CuentaEmisora = new CuentaEmisora()
  title: String = ''
  createModalShow: boolean = false
  editModalShow: boolean = false
  disabled: boolean = false

  created() {}
  async save() {
    
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: 'cuentaEmisora/update',
          data: this.cuentaEmisora
        })
      } else {
        await this.$store.dispatch({
          type: 'cuentaEmisora/create',
          data: this.cuentaEmisora
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    if (this.editing) {
      this.cuentaEmisora = Util.extend(true, {}, this.$store.state.cuentaEmisora.editCuentaEmisora)
    }
    if (this.editing) {
      this.title = 'EditCuentaEmisora'
    } else {
      this.title = 'AddCuentaEmisora'
    }
    this.setDisabled()
  }
  checkFormValidity() {
    const valid = (this.$refs.cuentaEmisoraForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.cuentaEmisora = new CuentaEmisora()
  }
  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.CuentaEmisora.Edit', 'OZONE.CuentaEmisora.Create', 'OZONE.CuentaEmisora.Delete')
  }
  cuentaEmisoraRule = {
    numero: {
      required: { required: true, regex: "^[a-zA-Z]{2}[0-9]{22}$", max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Numero')),
      trigger: 'blur'
    },
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Nombre')),
      trigger: 'blur'
    },
    msG_Transferencia: {
      required: { required: true, min: 3, max: 30 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('MSG_Transferencia')),
      trigger: 'blur'
    },
    entidadEmisora: {
      required: { required: true, min: 3 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('EntidadEmisora')),
      placeholder: this.L('EntidadEmisora'),
      trigger: 'blur'
    },
    direccionEmisora: {
      required: { required: true, min: 3 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('DireccionEmisora')),
      trigger: 'blur'
    },
    cifEmisor: {
      required: { required: true, regex: "(^[A-Wa-w]([0-9]{7})([0-9A-Ja-j]$))" },
      message: this.L('ThisFieldIsRequired', undefined, this.L('CIFEmisor')),
      trigger: 'blur'
    }
  }
}
