
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import editcuentaEmisora from "./editcuentaEmisora.vue";
import PeopleFind from "../../../components/People/people-find.vue";

class PageCuentaEmisoraRequest extends PageRequest {
  nomrbre: string
  numero: string
  msG_Transferencia: string
  entidadEmisora: string
  direccionEmisora: string
  cifEmisor: string
}

@Component({
  components: {
    editcuentaEmisora,
    PeopleFind
  }
})
export default class CuentaEmisora extends AbpBase {
  pagerequest: PageCuentaEmisoraRequest = new PageCuentaEmisoraRequest()
  creationTime: Date[] = []
  findModalShow: boolean = false
  createModalShow: boolean = false
  editModalShow: boolean = false
  pagination: any = {}

  searchDefinition: {}[] = [
    {
      fieldName: 'Nombre',
      fieldType: 'text',
      fieldBind: 'Nombre',
      value: ''
    },
    {
      fieldName: 'IBAN',
      fieldType: 'text',
      fieldBind: 'numero',
      value: ''
    },
    {
      fieldName: 'Mensaje',
      fieldType: 'text',
      fieldBind: 'msG_Transferencia',
      value: ''
    },
    {
      fieldName: 'Entidad',
      fieldType: 'text',
      fieldBind: 'entidadEmisora',
      value: ''
    },
    {
      fieldName: 'CIF',
      fieldType: 'text',
      fieldBind: 'cifEmisor',
      value: ''
    },
    {
      fieldName: 'Direccion',
      fieldType: 'text',
      fieldBind: 'direccionEmisora',
      value: ''
    }
  ]

  get list() {
    return this.$store.state.cuentaEmisora.list
  }
  get loading() {
    return this.$store.state.cuentaEmisora.loading
  }
  async create() {
    this.createModalShow = true
    this.pagerequest = new PageCuentaEmisoraRequest()
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value
      } else {
        delete this.pagerequest[element.fieldBind]
      }
    })
    await this.getpage()
    this.findModalShow = false
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('cuentaEmisora/setCurrentPage', pagination.current)
    this.getpage()

    await this.getpage()
  }
  editRow(item, index, button) {
    this.$store.commit('cuentaEmisora/edit', item)
    this.edit()
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('¿Desea eliminar esta cuenta emisora?'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'cuentaEmisora/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }

  edit() {
    this.editModalShow = true
  }
  hide() {
    this.findModalShow = false
  }
  pageChange(page: number) {
    this.$store.commit('cuentaEmisora/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('cuentaEmisora/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    await this.$store.dispatch({
      type: 'cuentaEmisora/getAll',
      data: this.pagerequest
    })
    const pagination = { ...this.pagination }
    pagination.total = this.$store.state.cuentaEmisora.totalCount
    pagination.pageSize = this.$store.state.cuentaEmisora.pageSize
    pagination.current = this.$store.state.cuentaEmisora.currentPage
    this.pagination = pagination
    
  }

  async created() {
    this.getpage()
  }

  async find() {
    this.findModalShow = true
  }

  get pageSize() {
    return this.$store.state.cuentaEmisora.pageSize
  }
  get totalCount() {
    return this.$store.state.cuentaEmisora.totalCount
  }
  get currentPage() {
    return this.$store.state.cuentaEmisora.currentPage
  }
  set currentPage(page) {
    this.$store.commit('cuentaEmisora/setCurrentPage', page)
    this.getpage()
  }
  columns = [
    {
      title: this.L('Cuenta'),
      scopedSlots: { customRender: 'func' }
    },
    {
      title: this.L('IBAN'),
      dataIndex: 'numero',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Mensaje Transferencia'),
      dataIndex: 'msG_Transferencia',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Dirección'),
      dataIndex: 'direccionEmisora',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('CIF'),
      dataIndex: 'cifEmisor',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Fecha Creacion'),
      dataIndex: 'creationTime',
      scopedSlots: { customRender: 'date' }
    },
    {
      scopedSlots: { customRender: 'editBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: 'removeBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    }
  ]
}
